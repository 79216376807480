import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { checkIsAvailableCampaignName, renameCampaign } from 'actions/campaign';
import { campaignNameFromListSelectorFactory } from 'selectors/campaign';

import ErrorMessage from 'components/ui/ErrorMessage';
import { ButtonAccent, ButtonTransparentDanger } from 'components/ui/button';
import { TextInput } from 'components/ui/inputs/TextInput';

import { useDebounce } from 'utils/hooks';

function CampaignRenameModal({ campaignId, onClose, headerText }) {
  const dispatch = useDispatch();
  const initialName = useSelector(
    campaignNameFromListSelectorFactory(campaignId)
  );
  const [nameIsValid, setNameIsValid] = useState(true);
  const [error, setError] = useState(false);
  const [newName, setNewName] = useState(initialName);
  const debouncedName = useDebounce(newName, 400);
  useEffect(() => {
    if (initialName !== newName) {
      checkIsAvailableCampaignName(newName, (value) => {
        setNameIsValid(value);
        if (value === false) {
          setError(true);
        } else {
          setError(false);
        }
      });
    } else {
      setError(false);
    }
  }, [debouncedName]);

  const onNewNameChange = useCallback(
    (e) => {
      setNewName(e.target.value);
      if (nameIsValid) {
        setNameIsValid(false);
        if (error) {
          setError(false);
        }
      }
    },
    [newName, nameIsValid]
  );

  const onRename = useCallback(() => {
    dispatch(renameCampaign(campaignId, newName));
    onClose();
  }, [newName, campaignId]);
  return (
    <Modal closeIcon open onClose={onClose} size="tiny">
      <Modal.Header content={headerText} />
      <Modal.Content>
        <TextInput
          autoFocus
          fluid
          large="true"
          // onChange={(e) => setNewName(e.target.value)}
          onChange={onNewNameChange}
          placeholder={t({ id: "campaign-rename.new-name" })}
          value={newName}
        />
        <ErrorMessage
          message={t({ id: "campaign-name-already-used-choose-another" })}
          show={error}
        />
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger onClick={onClose}>
          {t({ id: "cancel" })}
        </ButtonTransparentDanger>
        <ButtonAccent
          onClick={onRename}
          disabled={!nameIsValid || newName === initialName}
        >{t({ id: "rename" })}</ButtonAccent>
      </Modal.Actions>
    </Modal>
  );
}

CampaignRenameModal.propTypes = {
  campaignId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
};

CampaignRenameModal.defaultProps = {
  campaignId: null,
};

export default CampaignRenameModal;
