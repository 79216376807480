import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from 'components/ui/Header';
import { AnalyticsAwareCheckbox } from 'components/ui/inputs/Checkbox';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

const Container = styled.label`
  padding: ${svars.spaceMedium};
  display: inline-flex;
  cursor: pointer;
  border-radius: 8px;
  justify-content: space-between;
  width: 100%;

  background: ${({ checked }) =>
    checked ? svars.accentColorLightest : svars.colorLighterGrey};
  transition: ${svars.transitionBase};
  &:hover {
    background: ${svars.colorLightGrey};
  }
`;

function TogglableSection({
  title,
  subtitle,
  checked,
  onChange,
  style,
  gaCategory,
  gaAction,
  gaLabel,
  togglableSectionTestId,
}) {
  return (
    <Container style={style} checked={checked}>
      <Header style={{ width: '100%' }}>
        <span
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: svars.spaceSmaller,
          }}
        >
          <Trans render={capitalizedTranslation} id={title} />
          <AnalyticsAwareCheckbox
            gaCategory={gaCategory}
            gaAction={gaAction}
            gaLabel={gaLabel}
            checked={checked}
            onChange={onChange}
            toggle
            data-testid={togglableSectionTestId}
            style={{
              minWidth: '4rem',
              pointerEvents: 'none',
              marginLeft: svars.spaceNormal,
            }}
          />
        </span>
        <Header.Subheader>{subtitle}</Header.Subheader>
      </Header>
    </Container>
  );
}

TogglableSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  gaCategory: PropTypes.string.isRequired,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
  style: commonPropTypes.style,
  togglableSectionTestId: PropTypes.string,
};

TogglableSection.defaultProps = {
  gaAction: null,
  gaLabel: null,
  style: {},
  togglableSectionTestId: undefined,
};

export default TogglableSection;
