import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  addIdentificationFormElement,
  removeIdentificationFormElement,
  reorderIdentificationForm,
  resetWholeIdentificationFormToDefault,
  restoreIdentificationFormElement,
  saveCampaignConfigurationAsDraft,
  setExpandedForm,
  updateIdentificationFormElement,
} from 'actions/campaign';
import {
  campaignConfigurationIdentificationFormDeletionSelectorFactory,
  expandedFormSelectorFactory,
  onGenerateTagSetsOptionsSelector,
} from 'selectors/campaign';

import FormItemsManagementList from 'components/ui/ManagementList/FormItemsManagementList';

import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import CustomizationTabSegment, {
  TabSegmentTitle,
} from './CustomizationTabSegment';
import { FeedbackAppCustomizationInput } from './CustomizePageWording';
import TogglableSection from './TogglableSection';

const IDENTIFICATION_ON_FORM_FIELD_NAME = 'identification_form';
const IDENTIFICATION_FORM_PAGE = 'identification_wording';

function CustomizeIdentificationForm() {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const identificationForm = useMemoizedFactorySelector(
    campaignConfigurationIdentificationFormDeletionSelectorFactory,
    campaignId,
    IDENTIFICATION_ON_FORM_FIELD_NAME,
    true
  );

  const tagSetsOptions = useSelector(onGenerateTagSetsOptionsSelector);
  const expandedForm = useSelector(expandedFormSelectorFactory(campaignId));

  const endCreation = useCallback(() => {
    dispatch(saveCampaignConfigurationAsDraft(campaignId));
  }, []);
  const onRestoreIdentificationFormElement = useCallback(
    (element) =>
      dispatch(restoreIdentificationFormElement(campaignId, element.id)),
    []
  );
  const onRemoveIdentificationFormElement = useCallback(
    (element) =>
      dispatch(removeIdentificationFormElement(campaignId, element.id)),
    []
  );
  const onResetWholeIdentificationFormToDefault = useCallback(
    () => dispatch(resetWholeIdentificationFormToDefault(campaignId)),
    []
  );

  const onReorderIdentificationForm = useCallback(
    (reorderedItems) =>
      dispatch(reorderIdentificationForm(campaignId, reorderedItems)),
    [campaignId]
  );
  const onUpdateIdentificationFormElement = useCallback(
    (element) => dispatch(updateIdentificationFormElement(campaignId, element)),
    [campaignId]
  );
  const onAddIdentificationFormElement = useCallback(
    (element) => dispatch(addIdentificationFormElement(campaignId, element)),
    [campaignId]
  );
  const onToggleExpandedForm = useCallback(
    () => dispatch(setExpandedForm(campaignId, !expandedForm)),
    [campaignId, expandedForm]
  );
  return (
    <>
      <CustomizationTabSegment fitted style={{ flexShrink: 0 }}>
        <TogglableSection
          checked={expandedForm}
          onChange={onToggleExpandedForm}
          title={i18n._(t({ id: "always-expand-identification-form" }))}
          subtitle={i18n._(t({ id: "always-expand-identification-form-help" }))}
          style={{ marginBottom: svars.spaceNormalLarge }}
        />
        <FeedbackAppCustomizationInput
          inputLabel={i18n._(t({ id: "questionnaire-title" }))}
          campaignId={campaignId}
          page={IDENTIFICATION_FORM_PAGE}
          inputKey="header"
        />
      </CustomizationTabSegment>
      <CustomizationTabSegment fitted>
        <TabSegmentTitle
          title={i18n._(t({ id: 'identification-form' }))}
          help={i18n._(t({ id: "identification-form-help" }))}
        />
        <FormItemsManagementList
          items={identificationForm}
          addItemLabel={i18n._(t({ id: "add-identification-form-element" }))}
          editItemLabel={i18n._(t({ id: "edit-identification-form-element" }))}
          restoreItemLabel={i18n._(t({ id: "restore-identification-form-element" }))}
          removeItemLabel={i18n._(t({ id: "remove-identification-form-element" }))}
          resetAllLabel={i18n._(t({ id: "reset-identification-form-element" }))}
          emptyListHeader={i18n._(
            t({ id: "identification-form.element-list-empty-header" })
          )}
          emptyListMessage={i18n._(
            t({ id: "identification-form.element-list-empty-message" })
          )}
          onAddElement={onAddIdentificationFormElement}
          onUpdateElement={onUpdateIdentificationFormElement}
          onRestoreElement={onRestoreIdentificationFormElement}
          onRemoveElement={onRemoveIdentificationFormElement}
          onReorder={onReorderIdentificationForm}
          onResetToDefault={onResetWholeIdentificationFormToDefault}
          onCreateCallback={endCreation}
          tagSetsOptions={tagSetsOptions}
          mandatoryIsAllowed
        />
      </CustomizationTabSegment>
    </>
  );
}

CustomizeIdentificationForm.propTypes = {};

export default CustomizeIdentificationForm;
