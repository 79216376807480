import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import styled from 'styled-components';

import { updateCampaignLogo, updateLogoUrl } from 'actions/campaign';
import { updateThemeElement } from 'actions/survey';
import {
  AUTO_NAVIGATE_SPEED_OPTIONS,
  autoNavigateSelector,
  isFeedbackCampaignSelector,
  isRelaxedSelector,
  mainLogoSelector,
  navigationSpeedSelector,
  previousLogoSelector,
  primaryColorSelector,
} from 'selectors/survey';

import { LightHeader } from 'components/ui/Header';
import { ColorPicker } from 'components/ui/inputs/ColorPicker';
import ImageUpload from 'components/ui/inputs/FileUpload';
import RadioList from 'components/ui/inputs/RadioList';

// import uploadCampaignLogo from 'utils/apiHelpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import CustomizationTabSegment from './CustomizationTabSegment';
import TogglableSection from './TogglableSection';

const ThemeSettingContainer = styled.div`
  &&&&&& {
    display: flex;
    justify-content: space-between;
    margin: ${svars.spaceMedium} 0;
    align-items: center;
    flex-direction: row;
  }
`;

function CustomizeTheme() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();

  const diffLocation = useSelector(mainLogoSelector(campaignId, true));
  const configLocation = useSelector(mainLogoSelector(campaignId, false));
  const uploadedFile = diffLocation || configLocation;
  const previousFile = useSelector(previousLogoSelector(campaignId));

  const autoNavigate = useSelector(autoNavigateSelector(campaignId, true));
  const navigationSpeed = useSelector(
    navigationSpeedSelector(campaignId, true)
  );
  const primaryColor = useSelector(primaryColorSelector(campaignId, true));
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const isRelaxed = useSelector(isRelaxedSelector(campaignId, true));

  const toggleAutoNavigate = useCallback(() => {
    dispatch(updateThemeElement(campaignId, 'auto_navigate', !autoNavigate));
  }, [dispatch, campaignId, autoNavigate]);

  const onNavigationSpeedChange = useCallback(
    (value) => () => {
      dispatch(updateThemeElement(campaignId, 'navigation_speed', value));
    },
    [dispatch, campaignId]
  );

  const handleColorChange = useCallback(
    (color) => {
      dispatch(updateThemeElement(campaignId, 'primary', color));
    },
    [dispatch, campaignId]
  );

  const handleUpdateLogoUrl = (file) => {
    dispatch(updateLogoUrl(campaignId, file));
  };
  const handleCheckboxChange = useCallback(() => {
    dispatch(updateThemeElement(campaignId, 'relaxed', !isRelaxed));
  }, [dispatch, campaignId, isRelaxed]);

  const handleSelectedLogo = useCallback(
    (file) => {
      dispatch(updateCampaignLogo(campaignId, file));
    },
    [dispatch, campaignId]
  );

  return (
    <CustomizationTabSegment>
      <ThemeSettingContainer>
        <LightHeader inline="true" htmlFor="primaryColor">
          <Trans render={capitalizedTranslation} id="primary-color" />
          <LightHeader.Subheader>
            <Trans id="primary-color-helper" />
          </LightHeader.Subheader>
        </LightHeader>
        <ColorPicker
          type="color"
          id="primaryColor"
          initialColor={primaryColor}
          onChange={handleColorChange}
          colorPickerTestId="bo-color-picker-popup-button"
        />
      </ThemeSettingContainer>
      <ThemeSettingContainer>
        <LightHeader htmlFor="logoUpload" style={{ paddingRight: '10px' }}>
          <Trans render={capitalizedTranslation} id="logo-upload-title" />
          <LightHeader.Subheader>
            <Trans id="logo-helper" />
          </LightHeader.Subheader>
        </LightHeader>
        <ImageUpload
          uploadedFile={uploadedFile}
          previousFile={previousFile}
          handleUpdateLogoUrl={handleUpdateLogoUrl}
          handleSelectedFileChange={handleSelectedLogo}
        />
      </ThemeSettingContainer>
      {isFeedbackCampaign ? (
        <TogglableSection
          gaCategory="Campaign - customization"
          gaAction="toggle relaxed"
          gaLabel={`${campaignId}`}
          checked={isRelaxed}
          onChange={handleCheckboxChange}
          title={t({ id: 'relaxed' })}
          subtitle={t({ id: 'relaxed-description' })}
        />
      ) : (
        <>
          <TogglableSection
            gaCategory="Campaign - customization"
            gaAction="toggle auto-navigate"
            gaLabel={`${campaignId}`}
            checked={autoNavigate}
            onChange={toggleAutoNavigate}
            title={t({ id: 'auto-navigate' })}
            subtitle={t({ id: 'auto-navigate-description' })}
          />
          {autoNavigate ? (
            <RadioList
              subtitle={t({ id: 'auto-navigate-description' })}
              options={AUTO_NAVIGATE_SPEED_OPTIONS}
              selected={navigationSpeed}
              onChange={onNavigationSpeedChange}
              style={{ marginTop: svars.spaceNormal }}
            />
          ) : null}
        </>
      )}
    </CustomizationTabSegment>
  );
}

export default CustomizeTheme;
