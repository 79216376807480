import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';

import {
  addUserFormElement,
  removeFormElement,
  updateFormElement,
} from 'actions/campaign';
import {
  formSelectorFactory,
  onGenerateTagSetsOptionsSelector,
} from 'selectors/campaign';
import { useLingui } from '@lingui/react';


import FormItemsManagementList from 'components/ui/ManagementList/FormItemsManagementList';
import emptyPageUrl from 'components/ui/svg/undraw_link_shortener_mvf6.svg';

import { useMemoizedFactorySelector } from 'utils/hooks';

const CAMPAIGN_FIELD = 'url_form';

function UrlFormTabPage() {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const urlForm = useMemoizedFactorySelector(
    formSelectorFactory,
    campaignId,
    CAMPAIGN_FIELD
  );
  const tagSetsOptions = useSelector(onGenerateTagSetsOptionsSelector);
  const onAddUrlFormElement = useCallback(
    (element) =>
      dispatch(addUserFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onRemoveUrlFormElement = useCallback(
    (element) =>
      dispatch(removeFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onUpdateFormElement = useCallback(
    (element) =>
      dispatch(updateFormElement(campaignId, CAMPAIGN_FIELD, element)),
    [campaignId]
  );
  return (
    <FormItemsManagementList
      items={urlForm}
      addItemLabel={i18n._(t({ id: "add-url-form-element" }))}
      editItemLabel={i18n._(t({ id: "edit-url-form-element" }))}
      restoreItemLabel={i18n._(t({ id: "restore-url-form-element" }))}
      removeItemLabel={i18n._(t({ id: "remove-url-form-element" }))}
      resetAllLabel={i18n._(t({ id: "reset-url-form-element" }))}
      emptyListHeader={i18n._(t({ id: "url-form-element.list-empty-header" }))}
      emptyListMessage={i18n._(t({ id: "url-form-element.list-empty-message" }))}
      onAddElement={onAddUrlFormElement}
      onUpdateElement={onUpdateFormElement}
      onRemoveElement={onRemoveUrlFormElement}
      tagSetsOptions={tagSetsOptions}
      emptyDataIllustration={emptyPageUrl}
    />
  );
}

UrlFormTabPage.propTypes = {};

export default UrlFormTabPage;
