import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import { Divider } from 'semantic-ui-react';

import { setWordingToDefault, setWordingValue } from 'actions/campaign';
import { wordingValueSelectorFactory } from 'selectors/campaign';

import CustomizationInput from 'components/ui/inputs/CustomizationInput';

import CustomizationTabSegment from './CustomizationTabSegment';

export function FeedbackAppCustomizationInput({
  inputLabel,
  campaignId,
  page,
  inputKey,
  validate,
  errorMessage,
}) {
  const dispatch = useDispatch();
  const wordingValues = useSelector(
    wordingValueSelectorFactory(campaignId, page, inputKey)
  );

  const onChange = useCallback(
    (value) => {
      dispatch(setWordingValue(campaignId, page, inputKey, value));
    },
    [campaignId, page, inputKey]
  );
  const undoValueChange = useCallback(
    () => dispatch(setWordingToDefault(campaignId, page, inputKey)),
    [campaignId, page, inputKey]
  );
  return (
    <CustomizationInput
      label={inputLabel}
      value={wordingValues.value}
      onChange={onChange}
      validate={validate}
      errorMessage={errorMessage}
      resetValue={undoValueChange}
      initialValue={wordingValues.defaultWording}
    />
  );
}

FeedbackAppCustomizationInput.propTypes = {
  inputLabel: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  inputKey: PropTypes.string.isRequired,
  validate: PropTypes.func,
  errorMessage: PropTypes.string,
};

FeedbackAppCustomizationInput.defaultProps = {
  validate: null,
  errorMessage: null,
};

const customizePageWordingFactory = (page, inputLabels) => {
  function CustomizePageWording({ style, title, fitted }) {
    const { campaignId } = useParams();
    return (
      <CustomizationTabSegment style={style} fitted={fitted ? 'true' : null}>
        {title}
        {Object.entries(inputLabels).map(([inputKey, inputLabel], i) => (
          <React.Fragment key={inputKey}>
            {i ? <Divider /> : null}
            <FeedbackAppCustomizationInput
              key={`key_${inputKey}_string`}
              inputLabel={inputLabel}
              campaignId={campaignId}
              page={page}
              inputKey={inputKey}
            />
          </React.Fragment>
        ))}
      </CustomizationTabSegment>
    );
  }
  CustomizePageWording.propTypes = {
    style: PropTypes.shape({}),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fitted: PropTypes.bool,
  };

  CustomizePageWording.defaultProps = {
    style: {},
    title: null,
    fitted: false,
  };

  return CustomizePageWording;
};

export default customizePageWordingFactory;
