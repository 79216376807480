import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import {
  addUserFormElement,
  removeFormElement,
  updateFormElement,
} from 'actions/campaign';
import {
  formSelectorFactory,
  onGenerateTagSetsOptionsSelector,
} from 'selectors/campaign';

import FormItemsManagementList from 'components/ui/ManagementList/FormItemsManagementList';
import emptyPageUrl from 'components/ui/svg/undraw_annotation_re_h774.svg';

import { useMemoizedFactorySelector } from 'utils/hooks';

const CAMPAIGN_FIELD = 'annotation_form';

function AnnotationFormTabPage() {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  const annotationForm = useMemoizedFactorySelector(
    formSelectorFactory,
    campaignId,
    CAMPAIGN_FIELD
  );
  const tagSetsOptions = useSelector(onGenerateTagSetsOptionsSelector);
  const onAddAnnotationFormElement = useCallback(
    (element) =>
      dispatch(addUserFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onRemoveAnnotationFormElement = useCallback(
    (element) =>
      dispatch(removeFormElement(campaignId, CAMPAIGN_FIELD, element)),
    []
  );
  const onUpdateFormElement = useCallback(
    (element) =>
      dispatch(updateFormElement(campaignId, CAMPAIGN_FIELD, element)),
    [campaignId]
  );
  return (
    <FormItemsManagementList
      items={annotationForm}
      addItemLabel={i18n._(t({ id: 'add-annotation-form-element' }))}
      editItemLabel={i18n._(t({ id: 'edit-annotation-form-element' }))}
      restoreItemLabel={i18n._(t({ id: 'restore-annotation-form-element' }))}
      removeItemLabel={i18n._(t({ id: 'remove-annotation-form-element' }))}
      resetAllLabel={i18n._(t({ id: 'reset-annotation-form-element' }))}
      emptyListHeader={i18n._(
        t({ id: 'annotation-form-element.list-empty-header' })
      )}
      emptyListMessage={i18n._(
        t({ id: 'annotation-form-element.list-empty-message' })
      )}
      onAddElement={onAddAnnotationFormElement}
      onUpdateElement={onUpdateFormElement}
      onRemoveElement={onRemoveAnnotationFormElement}
      // onReorder={onReorderAnnotationForm}
      tagSetsOptions={tagSetsOptions}
      emptyDataIllustration={emptyPageUrl}
      mutableIsAllowed
      userFormElementIsAllowed
    />
  );
}

AnnotationFormTabPage.propTypes = {};

export default AnnotationFormTabPage;
