import { msg, t } from '@lingui/macro';

export const FORMS_ICON_MAP = {
  annotationFormFields: {
    icon: 'tags',
    iconHelp: msg({ id: 'field-belongs-to-annotation-form' }),
  },
  identificationFormFields: {
    icon: 'id badge',
    iconHelp: msg({ id: 'field-belongs-to-identification-form' }),
  },
  urlFormFields: {
    icon: 'share alternate',
    iconHelp: msg({ id: 'field-belongs-to-url-form' }),
  },
  surveyFormFields: {
    icon: 'file alternate outline',
    iconHelp: msg({ id: 'field-belongs-to-survey-form' }),
  },
};

const getCampaignSubRoutes = (canWrite) => [
  {
    to: 'monitor',
    header: t({ id: 'campaign-monitoring' }),
    description: t({ id: 'campaign-monitor.description' }),
    disabledHelp: t({ id: 'campaign-monitoring-read-disabled' }),
    ctaText: t({ id: 'campaign-monitoring-cta' }),
    icon: 'chart bar',
    actions: [
      {
        to: 'monitor',
        disabledHelp: t({ id: 'campaign-monitoring-read-disabled' }),
        ctaText: t({ id: 'campaign-monitoring-cta' }),
        icon: 'chart bar',
      },
      {
        to: 'monitor/search',
        disabledHelp: t({ id: 'campaign-monitoring-read-disabled' }),
        ctaText: t({ id: 'campaign-monitoring-responses-cta' }),
        icon: 'list alternate',
      },
    ],
  },
  {
    to: 'channels',
    header: t({ id: 'campaign-channels' }),
    description: t({ id: 'campaign-channels.description' }),
    ctaText: t({ id: 'campaign-channels-cta' }),
    disabledHelp: t({ id: 'campaign-monitoring-read-disabled' }),
    icon: 'share alternate',
    actions: [
      {
        to: 'channels',
        ctaText: t({ id: 'campaign-channels-cta' }),
        disabledHelp: t({ id: 'campaign-monitoring-read-disabled' }),
        icon: 'share alternate',
      },
    ],
  },
  ...(canWrite
    ? [
        {
          to: 'customize',
          header: t({ id: 'campaign-customization' }),
          description: t({ id: 'campaign-customization.description' }),
          ctaText: t({ id: 'campaign-customization-cta' }),
          disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
          icon: 'cog',
          actions: [
            {
              to: 'customize',
              ctaText: t({ id: 'campaign-customization-cta' }),
              disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
              icon: 'cog',
            },
            {
              to: 'customize/appearance',
              ctaText: t({ id: 'appearance' }),
              disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
              icon: 'paint brush',
            },
            {
              to: 'customize/privacy',
              ctaText: t({ id: 'help-and-privacy' }),
              disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
              icon: 'question',
            },
          ],
        },

        {
          to: 'administrate',
          header: t({ id: 'campaign-administration' }),
          description: t({ id: 'campaign-administration.description' }),
          ctaText: t({ id: 'campaign-administration-cta' }),
          disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
          icon: 'american sign language interpreting',
          actions: [
            {
              to: 'administrate/url-form',
              ctaText: t({ id: 'url-form' }),
              disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
              icon: FORMS_ICON_MAP.urlFormFields.icon,
            },
            {
              to: 'administrate/annotation-form',
              ctaText: t({ id: 'annotation-form' }),
              disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
              icon: FORMS_ICON_MAP.annotationFormFields.icon,
            },
            {
              to: 'administrate/members',
              ctaText: t({ id: 'members-and-permissions' }),
              disabledHelp: t({ id: 'campaign-monitoring-write-disabled' }),
              icon: 'users',
            },
          ],
        },
      ]
    : []),
];

export const campaignHomeRoute = {
  to: '',
  ctaText: msg({ id: 'back-home' }),
  icon: 'arrow left',
  active: true,
  color: 'blue',
};

export default getCampaignSubRoutes;
