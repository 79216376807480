// import Header from 'components/ui/Header';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { Trans } from '@lingui/macro';
import { Dimmer, Header, Icon } from 'semantic-ui-react';

import {
  closePreview,
  openPreview,
  reloadDraft,
  saveCampaignConfigurationAsDraft,
} from 'actions/campaign';
import {
  campaignConfigurationLoadingSelector,
  previewActivatedSelector,
  versionConflictSelector,
} from 'selectors/campaign';
import { isFeedbackCampaignSelector } from 'selectors/survey';

import BeatingLoader from 'components/ui/BeatingLoader';
import { AnalyticsAwareButton, ButtonDanger } from 'components/ui/button';

import * as svars from 'assets/style/variables';

import PreviewPane from './PreviewPane';
import SaveControl from './SaveControl';
import CustomizationTabs, {
  FEEDBACK_CUSTOMIZATION_PANES,
  SURVEY_CUSTOMIZATION_PANES,
} from './useCustomizationTabs';

const redirectToPane = (isFeedbackCampaign, pathname) => {
  const panes = isFeedbackCampaign
    ? FEEDBACK_CUSTOMIZATION_PANES
    : SURVEY_CUSTOMIZATION_PANES;

  let redirectTo = null;
  const firstPanePath = panes?.[0]?.to;

  if (pathname.endsWith('/customize')) {
    redirectTo = firstPanePath;
  } else if (panes.every((pane) => !pathname.endsWith(pane.to))) {
    redirectTo = `./${firstPanePath}`;
  }
  return redirectTo;
};

function CustomizeCampaignLayout() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState('');
  const onReloadDraft = useCallback(() => {
    dispatch(reloadDraft(campaignId));
  }, [campaignId, dispatch]);
  const versionConflict = useSelector(versionConflictSelector);
  const campaignConfigurationIsLoading = useSelector(
    campaignConfigurationLoadingSelector
  );
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const previewActivated = useSelector(previewActivatedSelector);

  const onTogglePreview = useCallback(async () => {
    if (previewActivated) {
      dispatch(closePreview());
    } else {
      await dispatch(saveCampaignConfigurationAsDraft(campaignId));
      dispatch(openPreview());
    }
  }, [campaignId, previewActivated, dispatch]);

  const redirectTo = redirectToPane(isFeedbackCampaign, pathname);
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }
  return (
    <>
      <CustomizationTabs campaignId={campaignId} setActiveTab={setActiveTab}>
        <SaveControl pageRoute={activeTab} campaignId={campaignId} />
      </CustomizationTabs>
      {campaignConfigurationIsLoading ? <BeatingLoader absolute /> : null}
      <PreviewPane
        activeTab={activeTab}
        onToggle={onTogglePreview}
        previewActivated={previewActivated}
      />
      <Outlet />
      {versionConflict && (
        <Dimmer active page blurring>
          <Header
            as="h1"
            icon
            inverted
            inline
            style={{ maxWidth: svars.textMaxWidth }}
          >
            <Icon name="warning sign" color="yellow" />
            <Trans id="draft-version-conflict.title" />
            <Header.Subheader style={{ marginTop: svars.spaceNormalLarge }}>
              <Trans id="draft-version-conflict.description" />
            </Header.Subheader>
          </Header>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: svars.spaceNormalLarge,
            }}
          >
            <AnalyticsAwareButton
              inputComponent={ButtonDanger}
              gaCategory="Campaign - customization"
              x
              gaAction="Use ending illustration"
              gaLabel={`${campaignId}`}
              type="submit"
              onClick={onReloadDraft}
            >
              <Trans id="reload-draft" />
            </AnalyticsAwareButton>
          </div>
        </Dimmer>
      )}
    </>
  );
}

CustomizeCampaignLayout.propTypes = {};

export default CustomizeCampaignLayout;
