import { Trans, t } from '@lingui/macro';
import { Grid } from 'semantic-ui-react';

import {
  CAMPAIGN_PERMISSION_OPTIONS,
  getPermissionType,
} from 'store/campaign/campaignPermissionsSlice';

import moment from 'moment';

import RowActions from 'components/ui/ManagementList/RowActions';
import { I18nDropdown } from 'components/ui/inputs/Dropdown';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

const CAMPAIGN_PERMISSION_ITEMS = CAMPAIGN_PERMISSION_OPTIONS.map((option) => ({
  key: option.type,
  i18nText: option.header,
  value: option.type,
}));

const membersManagementRowFactory = (
  onUpdatePermission,
  onMemberDelete,
  userFormatter,
  currentUserId
) => {
  function ViewFacetRow({ item }) {
    const disabled = currentUserId === item.user.id;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column
            width={5}
            verticalAlign="middle"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {userFormatter(item.user.id)}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: svars.fontColorLightest,
                marginRight: svars.spaceMedium,
              }}
            >
              <span>
                <Trans id="updated-on" />
                {` ${moment(item.update_date).format(svars.dateFormat)}`}
              </span>
            </div>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center" verticalAlign="middle">
            <I18nDropdown
              selection
              disabled={disabled}
              options={CAMPAIGN_PERMISSION_ITEMS}
              onChange={(e, { value }) =>
                onUpdatePermission({
                  permission: item,
                  newPermissionType: value,
                })
              }
              help="A help message"
              value={getPermissionType(item)}
            />
          </Grid.Column>
          <Grid.Column
            width={2}
            floated="right"
            textAlign="right"
            verticalAlign="middle"
          >
            <RowActions
              actions={[
                {
                  icon: 'trash',
                  onClick: onMemberDelete(item),
                  danger: true,
                  disabled,
                  disabledHelp: t({ id: 'cannot-delete-own-permission' }),
                },
              ]}
              item={item}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
  ViewFacetRow.propTypes = {
    item: commonPropTypes.viewFacet.isRequired,
  };
  return ViewFacetRow;
};

export default membersManagementRowFactory;
