import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Divider, Form } from 'semantic-ui-react';

import {
  toggleDisplayHomeScreenElement,
  updateHomeScreenElement,
} from 'actions/survey';
import {
  ctaTextHomeScreenSelector,
  displayHomeScreenSelector,
} from 'selectors/survey';

import Header, { LightHeader } from 'components/ui/Header';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import TogglableSection from './TogglableSection';

function HomeScreenSettings() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const ctaText = useSelector(ctaTextHomeScreenSelector(campaignId, true));
  const displayHomeScreen = useSelector(
    displayHomeScreenSelector(campaignId, true)
  );
  const onElementChange = useCallback(
    (key) =>
      (e, { value }) => {
        dispatch(updateHomeScreenElement(campaignId, key, value));
      },
    [dispatch, campaignId]
  );
  const onActivateHomeScreen = useCallback(
    (e, { checked }) => {
      dispatch(toggleDisplayHomeScreenElement(campaignId, checked));
    },
    [dispatch, campaignId]
  );
  return (
    <>
      <Form.Field
        style={{
          margin: `0 ${svars.spaceMedium} ${svars.spaceMedium} ${svars.spaceMedium}`,
        }}
      >
        <Header>
          <Trans render={capitalizedTranslation} id="home-settings" />
        </Header>
      </Form.Field>
      <TogglableSection
        checked={displayHomeScreen}
        onChange={onActivateHomeScreen}
        title={t({ id: "show-home-page" })}
        subtitle={t({ id: "show-home-page-description" })}
        gaCategory="Campaign - customization"
        gaAction="Use home page"
        gaLabel={`${campaignId}`}
      />
      <Divider fitted />
      <Form.Field style={{ margin: svars.spaceMedium }}>
        <LightHeader>
          <Trans id="button-label" />
        </LightHeader>
        <LimitedTextInput
          maxCharacters={40}
          value={ctaText}
          onChange={onElementChange('button_label')}
          disabled={!displayHomeScreen}
        />
      </Form.Field>
    </>
  );
}

HomeScreenSettings.propTypes = {};
HomeScreenSettings.defaultProps = {};

export default HomeScreenSettings;
