import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { useConceptLabelFormatter } from 'reducers/entityLabelFormatter';
import { conceptColorFormatterSelector } from 'selectors/entities';
import { isFeedbackCampaignSelector } from 'selectors/survey';
import {
  focusChannelSelector,
  focusOnNextChannel,
  setFocusChannelId,
} from 'store/monitor/channelsSlice';

import PaneFields from 'components/ui/panels/PaneFields';
import SidePane from 'components/ui/panels/SidePane';
import { HiddenQuestionsCell } from 'components/ui/table/cells/CampaignCells';
import QRCodeCell from 'components/ui/table/cells/QRCodeCell';
import {
  ChannelNameCell,
  CopyableOpenableTextCell,
  DateTimeCell,
  LabelCell,
} from 'components/ui/table/cells/ReactTableCell';
import { ActivityIndicatorCell } from 'components/ui/table/cells/dotCells';

import config from 'config';
import { dateTimeFormatter } from 'utils/formatter';
import { useMemoizedFactorySelector } from 'utils/hooks';

import { CAMPAIGN_COMMON_FORMS_FIELDS } from '../monitor/CampaignReviewPane';
import {
  enrichFieldProps,
  useCampaignFormFields,
  useUrlForm,
} from '../useCampaignFields';

const appRouteFormatterAndAccessor = {
  accessor: (item) => item.app_route,
  formatter: (route) => `${config.PREVIEW_URL}${route}`,
};

const useChannelPaneFieldsFactory = (isFeedbackCampaign) => {
  const { i18n } = useLingui();
  const conceptLabelFormatter = isFeedbackCampaign
    ? useConceptLabelFormatter()
    : null;
  const conceptColorFormatterBase = isFeedbackCampaign
    ? useSelector(conceptColorFormatterSelector)
    : null;
  const conceptColorFormatter = useCallback(
    (aChannel) => conceptColorFormatterBase(aChannel?.concept?.id),
    [conceptColorFormatterBase]
  );
  const { urlFormFields } = useCampaignFormFields({ useUrlForm }, false);
  const useChannelPaneFields = () => {
    const fields = useMemo(
      () => [
        {
          header: null,
          fields: [
            {
              header: t({ id: "name" }),
              accessor: (item) => (
                <ChannelNameCell
                  value={{ name: item.name, archived: item.archived }}
                />
              ),
              inline: true,
              testid: 'channel-header',
            },
            ...(isFeedbackCampaign
              ? [
                  {
                    header: t({ id: "concept" }),
                    accessor: (item) => item?.concept?.id,
                    inline: true,
                    testid: 'channel-concept',
                    formatter: conceptLabelFormatter,
                    colorFormatter: conceptColorFormatter,
                    Cell: LabelCell,
                  },
                ]
              : [
                  {
                    header: t({ id: "hidden-questions" }),
                    accessor: (item) => item.hidden_questions,
                    inline: true,
                    testid: 'hidden-questions',
                    Cell: HiddenQuestionsCell,
                  },
                ]),
            {
              header: t({ id: "creation-date" }),
              accessor: (item) => item.create_date,
              formatter: dateTimeFormatter,
              inline: true,
              testid: 'channel-creation-date',
              Cell: DateTimeCell,
            },
            enrichFieldProps(
              {
                id: 'n_feedback',
                label: t({ id: "responses" }),
                accessor: (item) => item.n_feedback,
                Cell: ActivityIndicatorCell,
                inline: true,
                testid: 'channel-response-indicator',
              },
              false
            ),
          ],
        },
        { ...CAMPAIGN_COMMON_FORMS_FIELDS[useUrlForm], fields: urlFormFields },
        {
          header: t({ id: "accesses" }),
          headerIcon: 'linkify',
          fields: [
            {
              header: t({ id: "url" }),
              Cell: CopyableOpenableTextCell,
              testid: 'channel-url',
              ...appRouteFormatterAndAccessor,
            },
            {
              header: t({ id: "qr-code" }),
              Cell: QRCodeCell,
              testid: 'channel-qrcode',
              ...appRouteFormatterAndAccessor,
              getCellProps: (item) => ({ title: item.name }),
            },
          ],
        },
      ],
      [conceptColorFormatter, conceptLabelFormatter, i18n.locale]
    );
    return fields;
  };
  return useChannelPaneFields;
};

function ChannelPane() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const channel = useMemoizedFactorySelector(focusChannelSelector, campaignId);

  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const fields = useChannelPaneFieldsFactory(isFeedbackCampaign)();
  const onToggle = useCallback(() => {
    if (channel) dispatch(setFocusChannelId(null));
  }, [channel, campaignId]);
  const onFocusPreviousChannel = useCallback(() => {
    if (channel)
      dispatch(focusOnNextChannel({ campaignId, selectNext: false }));
  }, [channel, campaignId]);
  const onFocusNextChannel = useCallback(() => {
    if (channel) dispatch(focusOnNextChannel({ campaignId, selectNext: true }));
  }, [channel, campaignId]);

  return (
    <SidePane
      dimmed
      onToggle={onToggle}
      animation="push"
      direction="right"
      visible={channel !== null}
      width="very wide"
      testid="bo-campaign-channel-panel-toggle-off-button"
    >
      <SidePane.Header
        title={<Trans id="channel-details"/>}
        onToggle={onToggle}
        onSelectPrevious={onFocusPreviousChannel}
        onSelectNext={onFocusNextChannel}
        onSelectPreviousHelp={t({ id: "previous-channel" })}
        onSelectNextHelp={t({ id: "next-channel" })}
        gaCategory="Campaign - channel panel"
        testid="bo-campaign-channel-panel-toggle-prev-after-button"
      />
      <SidePane.Body>
        {channel
          ? fields?.map(({ header, headerIcon, fields: sectionFields }) => (
              <PaneFields
                key={header}
                header={header}
                headerIcon={headerIcon}
                fields={sectionFields}
                reviewItem={channel}
              />
            ))
          : null}
      </SidePane.Body>
    </SidePane>
  );
}
ChannelPane.propTypes = {};
export default ChannelPane;
