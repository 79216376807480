import { Outlet } from 'react-router-dom';

import { msg } from '@lingui/macro';
import styled from 'styled-components';

import Segment from 'components/ui/Segment';
import { PageLayout } from 'components/ui/layout/Page';
import NavigationTabs from 'components/ui/navigation/NavigationTabs';

const panes = [
  {
    content: msg({ id: 'url-form' }),
    to: './url-form',
  },
  {
    content: msg({ id: 'annotation-form' }),
    to: './annotation-form',
  },
  {
    content: msg({ id: 'members-and-permissions' }),
    to: './members',
  },
];
const FullHeightSegment = styled(Segment)`
  &&&& {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    overflow: hidden;
  }
`;
function CampaignAdministrationPage() {
  return (
    <PageLayout simple>
      <NavigationTabs panes={panes} />
      <FullHeightSegment>
        <Outlet />
      </FullHeightSegment>
    </PageLayout>
  );
}

CampaignAdministrationPage.propTypes = {};
CampaignAdministrationPage.defaultProps = {};

export default CampaignAdministrationPage;
